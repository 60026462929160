
































import Component from 'vue-class-component';
import Vue from 'vue';
import RsLoading from '@/shared/components/loading/loading.vue';
import RsDashboardWidgetOverview from '@/modules/dashboard/components/widgets/overview.vue';
import RsDashboardWidgetInterview from '@/modules/dashboard/components/widgets/interview.vue';
import {Action, Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {InterviewGetters} from '@/modules/interview/store/getters';
import InterviewModule from '@/modules/interview';
import {QuizActions} from '@/modules/quiz/store/actions';
import QuizModule from '@/modules/quiz';
import RsDashboardJobStatusWidget from '@/modules/dashboard/components/global/job-status/job-status-widget.vue';
import RsDashboardWidgetJobs from '@/modules/dashboard/components/widgets/jobs.vue';

@Component({
  components: {
    RsDashboardWidgetJobs,
    RsDashboardJobStatusWidget, RsDashboardWidgetInterview, RsDashboardWidgetOverview, RsLoading},
  metaInfo: {
    title: 'Dashboard'
  }
})
export default class DashboardIndex extends Vue {
  @Action(InterviewGetters.GET_INTERVIEWS, {namespace: InterviewModule.namespace}) getInterviews: () => void;
  @Action(QuizActions.GET_QUIZZES, {namespace: QuizModule.namespace}) getQuizzes: () => void;

  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  mounted(){
    this.getInterviews();
    this.getQuizzes();
  }
}
