import {IApiTalent} from '@/shared/models/IApiTalent';

export abstract class ProfileCompletionService {
  static personalInfoCompletion(talent: IApiTalent): boolean {
    if (!talent.properties.TALENT_NAME) {
      return false;
    }
    if (!talent.properties.TALENT_LAST_NAME) {
      return false;
    }
    if (!talent.country) {
      return false;
    }
    return !!talent.properties.LOCATION;
  }

  static skillsCompletion(talent: IApiTalent): boolean {
    if(!(talent.properties.TALENT_ROLE_EXPERIENCES && talent.properties.TALENT_ROLE_EXPERIENCES.length)){
      return false;
    }
    if(!talent.properties.TALENT_TECH_EXPERIENCES || talent.properties.TALENT_TECH_EXPERIENCES.length < 1){
      return false;
    }
    return !(!talent.properties.TALENT_MAIN_SKILLS || talent.properties.TALENT_MAIN_SKILLS.length < 1);
  }

  static referencesCompletion(talent: IApiTalent): boolean {
    return (talent.socialConnections || []).length > 0
  }

  static preferencesCompletion(talent: IApiTalent): boolean {
    if(!talent.properties.TALENT_INTEREST_STATUS || talent.properties.TALENT_INTEREST_STATUS.length < 1){
      return false
    }
    if(!talent.properties.COMPENSATION?.FROM_VALUE){
      return false;
    }
    if(!talent.properties.COMPENSATION?.TO_VALUE){
      return false;
    }
    return !(!talent.properties.TALENT_PREFERRED_ROLES || talent.properties.TALENT_PREFERRED_ROLES.length < 1);

  }

  static workCompletion(talent: IApiTalent): boolean {
    return !(!talent.projectExperiences || talent.projectExperiences.length < 1);
  }
}

export const PROFILE_COMPLETION_SERVICE = ProfileCompletionService;
