























































































































































































































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import InterviewModule from '@/modules/interview';
import { IApiTalentInterviewAvailability } from '@/shared/models/IApiTalentInterviewAvailability';
import { InterviewActions } from '@/modules/interview/store/actions';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';
import { AssesmentsRouter } from '@/modules/dashboard/modules/assesments/router';
import { IApiTalentQuiz } from '@/shared/models/IApiTalentQuiz';
import { CALENDLY_LINK_TYPE } from '@/shared/constants/CALENDLY_LINK_TYPE';
import { DATE_SERVICE } from '@/shared/services/date.service';
import { InterviewData } from '@/modules/interview/constants/interview-data';
import { InterviewRouter } from '@/modules/interview/router';
import QuizModule from '@/modules/quiz';
import { QuizGetters } from '@/modules/quiz/store/getters';
import RsLoading from '@/shared/components/loading/loading.vue';
import JobApply from '@/modules/dashboard/components/widgets/JobApply.vue';
import { JobsActions } from '@/modules/dashboard/modules/jobs/store/actions';
import DashboardModule from '@/modules/dashboard';
import JobsModule from '@/modules/dashboard/modules/jobs';
import { JobsGetters } from '@/modules/dashboard/modules/jobs/store/getters';
import { IApiJob } from '@/shared/models/IApiJob';

enum InterviewStatus {
  NOT_AVAILABLE,
  FAILED,
  AVAILABLE,
  SCHEDULED,
  COMPLETED
}

@Component({
  name: 'rs-dashboard-widget-interview',
  components: { RsLoading, RsLinkIcon, JobApply },
})
export default class DashboardWidgetInterview extends Vue {
  @Action(JobsActions.GET_APPLIED_JOBS, { namespace: `${DashboardModule.namespace}/${JobsModule.namespace}` })
  getAppliedJobs: () => Promise<void>;
  @Getter(JobsGetters.GET_APPLIED_JOBS, { namespace: `${DashboardModule.namespace}/${JobsModule.namespace}` })
  appliedJobs: IApiJob[];
  @Getter(InterviewActions.GET_INTERVIEWS, { namespace: InterviewModule.namespace })
  interviews: IApiTalentInterviewAvailability[];
  @Getter(QuizGetters.GET_QUIZZES, { namespace: QuizModule.namespace }) quizzes: IApiTalentQuiz[];

  InterviewStatus = InterviewStatus;
  InterviewData = InterviewData;
  InterviewRouter = InterviewRouter;
  AssesmentsRouter = AssesmentsRouter;
  CALENDLY_LINK_TYPE = CALENDLY_LINK_TYPE;

  get quizzesCompleted() {
    return this.quizzes.map((quiz) => quiz.passed).filter((passed) => passed).length > 0;
  }

  get getTechnicalInterview() {
    return this.interviews
      .filter((interview) => ![
        CALENDLY_LINK_TYPE.FINAL_INTERVIEW,
        CALENDLY_LINK_TYPE.COMPANY_CALL,
      ].includes(interview.type));
  }

  get technicalInterviewStatus(): InterviewStatus {
    const completed = this.getTechnicalInterview
      .map((interview) => interview.completed)
      .filter((completed) => completed);
    if (completed.length > 0) {
      return InterviewStatus.COMPLETED;
    }
    const scheduled = this.getTechnicalInterview
      .map((interview) => !!interview.scheduledOn && !interview.completed)
      .filter((scheduled) => scheduled);
    if (scheduled.length > 0) {
      return InterviewStatus.SCHEDULED;
    }
    const available = this.getTechnicalInterview
      .map((interview) => interview.available)
      .filter((available) => available);
    if (available.length > 0) {
      return InterviewStatus.AVAILABLE;
    }
    const failed = this.getTechnicalInterview
      .map((interview) => !interview.available && interview.availableOn)
      .filter((failed) => failed);
    if (failed.length > 0) {
      return InterviewStatus.FAILED;
    }
    return InterviewStatus.NOT_AVAILABLE;
  }

  async created() {
    await this.getAppliedJobs();
  }

  get activeTechnicalInterview(): IApiTalentInterviewAvailability | undefined {
    if (this.technicalInterviewStatus === InterviewStatus.COMPLETED) {
      return this.getTechnicalInterview
        .find((interview) => interview.completed);
    } else if (this.technicalInterviewStatus === InterviewStatus.SCHEDULED) {
      return this.getTechnicalInterview
        .find((interview) => !!interview.scheduledOn && !interview.completed);
    } else if (this.technicalInterviewStatus === InterviewStatus.AVAILABLE) {
      return this.getTechnicalInterview
        .find((interview) => interview.available);
    }
    return this.getTechnicalInterview
      .find((interview) => !interview.available && interview.availableOn);
  }

  get getFinalInterview() {
    return this.interviews
      .find((interview) => interview.type === CALENDLY_LINK_TYPE.FINAL_INTERVIEW);
  }

  get finalInterviewStatus(): InterviewStatus {
    if (this.getFinalInterview?.completed) {
      return InterviewStatus.COMPLETED;
    }
    if (!!this.getFinalInterview?.scheduledOn && !this.getFinalInterview?.completed) {
      return InterviewStatus.SCHEDULED;
    }
    if (this.getFinalInterview?.available) {
      return InterviewStatus.AVAILABLE;
    }
    if (!this.getFinalInterview?.available && this.getFinalInterview?.availableOn) {
      return InterviewStatus.FAILED;
    }
    return InterviewStatus.NOT_AVAILABLE;
  }

  formatDate(date: string) {
    return DATE_SERVICE.format(date, 'ddd MMM D, h:mm A UTCZ');
  }
}
