



















































import { PublicJobsRouter } from '@/modules/jobs/router';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import DashboardModule from '@/modules/dashboard';
import JobsModule from '@/modules/dashboard/modules/jobs';
import { JobsGetters } from '@/modules/dashboard/modules/jobs/store/getters';
import { IApiJob } from '@/shared/models/IApiJob';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';

@Component({
  components: {
    RsLinkIcon,
  }
})
export default class JobApply extends Vue {
  @Getter(JobsGetters.GET_APPLIED_JOBS, { namespace: `${DashboardModule.namespace}/${JobsModule.namespace}` })
  appliedJobs: IApiJob[];

  get appliedToAny(): boolean {
    return this.appliedJobs.length > 0;
  }

  JobsRouter = PublicJobsRouter;
}
