






























































































































































































































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import QuizModule from '@/modules/quiz';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {ProfileEditSections, ProfileRouter} from '@/modules/dashboard/modules/profile/router';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {PROFILE_COMPLETION_SERVICE} from '@/modules/dashboard/modules/profile/services/profile-completion.service';
import RsLoading from '@/shared/components/loading/loading.vue';
import {QuizGetters} from '@/modules/quiz/store/getters';
import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import RsButton from '@/shared/components/buttons/button.vue';
import {AssesmentsRouter} from '@/modules/dashboard/modules/assesments/router';
import {rolesInfo} from '@/shared/constants/ROLES';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  name: 'rs-dashboard-widget-overview',
  components: {RsButton, RsLoading, RsIcon, RsLinkIcon}
})
export default class DashboardWidgetOverview extends Vue {
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;
  @Getter(QuizGetters.GET_QUIZZES, {namespace: QuizModule.namespace}) quizzes: IApiTalentQuiz[];

  ProfileRouter = ProfileRouter;
  ProfileEditSections = ProfileEditSections;
  AssesmentsRouter = AssesmentsRouter;
  rolesInfo = rolesInfo;
  Icons = Icons;

  complete = PROFILE_COMPLETION_SERVICE;

  get completedQuizzes() {
    return this.quizzes.filter((quiz) => quiz.passed && quiz.roles.length > 0);
  }

  get completedProfile(){
    return this.complete.personalInfoCompletion(this.user)
    && this.complete.skillsCompletion(this.user)
    && this.complete.workCompletion(this.user)
    && this.complete.referencesCompletion(this.user)
  }
}
