
























































import Component from 'vue-class-component';
import Vue from 'vue';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';
import {Action, Getter} from 'vuex-class';
import {JobsActions} from '@/modules/dashboard/modules/jobs/store/actions';
import JobsModule from '@/modules/dashboard/modules/jobs';
import DashboardModule from '@/modules/dashboard';
import {JobsGetters} from '@/modules/dashboard/modules/jobs/store/getters';
import {IApiJob} from '@/shared/models/IApiJob';
import {JobsRouter} from '@/modules/dashboard/modules/jobs/router';

@Component({
  name: 'rs-dashboard-widget-jobs',
  components: {RsLinkIcon}
})
export default class DashboardWidgetJobs extends Vue {
  @Action(JobsActions.GET_FEATURED_JOBS, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) getJobs: () => Promise<void>;
  @Getter(JobsGetters.GET_FEATURED_JOBS, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) jobs: IApiJob[];

  JobsRouter = JobsRouter;

  formatSalary(salary: number){
    if(salary % 1000 > 0){
      return `${salary/1000}.${salary%1000/100}`
    }
    return salary/1000;
  }

  created() {
    this.getJobs();
  }
}
